export const CarouselData = [
    {
      url:
        "https://firebasestorage.googleapis.com/v0/b/secondhand-upload.appspot.com/o/files%2Fchair1.webp?alt=media&token=a0336575-7e4a-4dbe-96da-ccd084c898b1",
      },
    {
      url:
        "https://firebasestorage.googleapis.com/v0/b/secondhand-upload.appspot.com/o/files%2Fchair3.webp?alt=media&token=d8b8e0c1-120e-403c-9273-d829c7d69ac7",
    },
    {
      url:
        "https://firebasestorage.googleapis.com/v0/b/secondhand-upload.appspot.com/o/files%2Fchair2.webp?alt=media&token=0b4ebf1e-d944-471e-a344-dbc6a2155d7a",
    },
  ];